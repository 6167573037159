import { formInitialState, FormItemType } from './initialState';
import { TAppFormActions, TAppFormState } from './types';

export const APP_FORM_ACTIONS = {
  CHANGE_PARAMS: 'CHANGE_PARAMS' as const,
  SELECT_NODE_TYPE: 'SELECT_NODE_TYPE' as const,
  ADD_FORM_PROPERTIES: 'ADD_FORM_PROPERTIES' as const,
  ADD_FORM_EDGES: 'ADD_FORM_EDGES' as const,
  SET_FORM_DATA: 'SET_FORM_DATA' as const,
  DELETE_FORM_ITEM: 'DELETE_FORM_ITEM' as const,
  UPDATE_FORM_BACKGROUND: 'UPDATE_FORM_BACKGROUND' as const,
  SAVE_ITEM: 'SAVE_ITEM' as const,
  SHOW_VIEW: 'SHOW_VIEW' as const,
  CHANGE_FORM_ITEM_VISUAL: 'CHANGE_FORM_ITEM_VISUAL' as const,
  RESET_FORM_DATA: 'RESET_FORM_DATA' as const,
  CHANGE_APPLICATION_FORM_STATUS: 'CHANGE_APPLICATION_FORM_STATUS' as const,
  DELETE_APP_FORM_ITEM: 'DELETE_APP_FORM_ITEM' as const,
};

export const reducer = (state: TAppFormState, action: TAppFormActions) => {
  const { type, payload } = action;
  switch (type) {
    /**
     * @description change params [page, size, search]
     *
     * @param page
     * @param search
     */
    case APP_FORM_ACTIONS.CHANGE_PARAMS: {
      return {
        ...state,
        params: payload,
      };
    }
    /**
     * @description select nodeType Id left bar
     *
     * @param id
     */
    case APP_FORM_ACTIONS.SELECT_NODE_TYPE: {
      return {
        ...state,
        nodeTypeId: payload,
        form: formInitialState, // reset form when change nodeTypeId
      };
    }

    /**
     * @description handle left bar checkbox update form properties
     *
     * @param Array
     */

    case APP_FORM_ACTIONS.ADD_FORM_PROPERTIES: {
      const updatedForm = { ...state.form };

      const form_properties = (payload || []).map(({ id, name, ref_property_type_id }) => {
        const index = updatedForm?.form_properties.findIndex((item) => item.project_type_property_id === id);

        if (index === -1) {
          return {
            project_type_property_id: id,
            question: '',
            placeholder: '',
            hover: '',
            ref_property_type_id,
            touched: true,
            property_type: {
              id,
              name,
              display_name: name,
              ref_property_type_id,
            },
            required: false,
          };
        }
        return updatedForm.form_properties[index];
      });

      return {
        ...state,
        form: { ...state.form, form_properties },
      };
    }

    /**
     * @description handle left bar checkbox update form edges
     *
     * @param Array
     */

    case APP_FORM_ACTIONS.ADD_FORM_EDGES: {
      const updateForm = { ...state.form };

      const form_edges = (payload || []).map(({ id, name }) => {
        const index = updateForm?.form_edges?.findIndex((item) => item?.project_edge_type_id === id);
        if (index === -1) {
          return {
            project_edge_type_id: id,
            question: '',
            placeholder: '',
            hover: '',
            required: false,
            touched: true,
            edge_type: {
              name,
              id,
            },
          };
        }
        return updateForm.form_edges[index];
      });

      return {
        ...state,
        form: { ...state.form, form_edges },
      };
    }

    /**
     * @description SET success fetch data
     *
     * @param Object
     */

    case APP_FORM_ACTIONS.SET_FORM_DATA: {
      return {
        ...state,
        form: payload,
        nodeTypeId: payload?.project_type_id,
      };
    }

    /**
     * @description RESET form when params not form_id
     *
     * @param Object
     */

    case APP_FORM_ACTIONS.RESET_FORM_DATA: {
      return {
        ...state,
        form: formInitialState,
        nodeTypeId: '',
      };
    }

    /**
     * @description Delete form item
     *
     * @param Object
     */

    case APP_FORM_ACTIONS.DELETE_FORM_ITEM: {
      const updateForm = { ...state.form };
      let deleteItem = updateForm[FormItemType.PROPERTY].filter(
        (item) => item?.project_type_property_id !== payload?.id
      );
      if (payload.type === FormItemType.EDGE) {
        deleteItem = updateForm[FormItemType.EDGE].filter((item) => item?.project_edge_type_id !== payload?.id);
      }
      return {
        ...state,
        form: { ...updateForm, [payload.type]: deleteItem },
      };
    }

    /**
     * @description Update form item when save add id
     *
     * @param Object
     */

    case APP_FORM_ACTIONS.SAVE_ITEM: {
      const updateForm = { ...state.form };

      if (payload.type === FormItemType.EDGE) {
        const index = updateForm[FormItemType.EDGE].findIndex(
          (item) => item?.project_edge_type_id === payload.project_edge_type_id
        );
        if (index !== -1) {
          updateForm[FormItemType.EDGE][index].id = payload.id;
          updateForm[FormItemType.EDGE][index].touched = false;
        }
      } else {
        const index = updateForm[FormItemType.PROPERTY].findIndex(
          (item) => item?.project_type_property_id === payload.project_type_property_id
        );
        if (index !== -1) {
          updateForm[FormItemType.PROPERTY][index].id = payload.id;
          updateForm[FormItemType.PROPERTY][index].touched = false;
        }
      }

      return {
        ...state,
        form: updateForm,
      };
    }

    /**
     * @description Update form background image
     *
     * @params string;
     */

    case APP_FORM_ACTIONS.UPDATE_FORM_BACKGROUND: {
      const updateForm = { ...state.form };
      updateForm.background_image = payload;
      return {
        ...state,
        form: updateForm,
      };
    }

    /**
     * @description Update app form drawer show view
     *
     * @params boolean;
     */

    case APP_FORM_ACTIONS.SHOW_VIEW: {
      return {
        ...state,
        isShowView: payload,
      };
    }

    /**
     * @description Update app form drawer form item visual
     *
     * @param {string} id - property id;
     * @param {string} text - form name
     * @param {string} text -  value can be string | boolean
     */

    case APP_FORM_ACTIONS.CHANGE_FORM_ITEM_VISUAL: {
      const updateForm = { ...state.form };

      if (payload.itemType) {
        const index = updateForm?.[FormItemType.EDGE]?.findIndex((edge) => edge?.project_edge_type_id === payload.id);
        if (index !== -1) {
          updateForm[FormItemType.EDGE][index][payload.type] = payload.text;
          updateForm[FormItemType.EDGE][index].touched = true;
        }
        return {
          ...state,
          form: updateForm,
        };
      } else {
        const index = updateForm?.[FormItemType.PROPERTY]?.findIndex(
          (property) => property?.project_type_property_id === payload.id
        );

        if (index !== -1) {
          if (updateForm[FormItemType.PROPERTY] && updateForm?.[FormItemType.PROPERTY][index]) {
            updateForm[FormItemType.PROPERTY][index][payload.type] = payload.text;
            updateForm[FormItemType.PROPERTY][index].touched = true;
          }
        }

        return {
          ...state,
          form: updateForm,
        };
      }
    }

    /**
     * @description Update form status
     *
     * @param {string} status - string
     */

    case APP_FORM_ACTIONS.CHANGE_APPLICATION_FORM_STATUS: {
      return {
        ...state,
        form: { ...state.form, status: payload },
      };
    }

    /**
     * @desription DELETE app form item
     *
     */

    case APP_FORM_ACTIONS.DELETE_APP_FORM_ITEM: {
      return {
        ...state,
        deletedIds: payload,
      };
    }

    default: {
      return state;
    }
  }
};
