import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { IUseMargeProjectData, Params, UseMargeProjectOptions } from 'api/types';
import { PATHS } from 'helpers/constants';
import { useNavigate } from 'react-router-dom';

export const useMargeProject = (options?: UseMargeProjectOptions) => {
  const url = '/projects/merge';
  const navigate = useNavigate();

  const mutation = useMutation(
    (values: Params) => {
      return client.post(url, values).then((response) => response.data);
    },
    {
      onSuccess: (data: IUseMargeProjectData) => {
        options?.onSuccess?.(data);
        {
          data?.id === null ? navigate(PATHS.PROJECTS) : navigate(PATHS.DATA_SHEET.replace(':id', data?.id || ''));
        }
      },
    }
  );
  return mutation;
};
