import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { Flex, Form } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { useAppForms } from 'context/app-forms';
import { AppFormView } from './app-form-view';
import { DeleteModal } from 'components/modal/delete-modal';
import { FormContent } from '../style';
import { Header } from './header';
import { Footer } from './footer';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { useDeleteAppFormProperty } from 'api/app-forms/use-delete-form-property';
import { useCreateFormProperty } from 'api/app-forms/use-create-form-property';
import { TFormType } from 'api/app-forms/types';
import { PropertyTypes } from 'components/form/property/types';
import { FormItem } from './form-item';
import { useCreateFormEdge } from 'api/app-forms/use-create-app-form-edge';
import { deletedIdsInitialState, FormItemType } from 'context/app-forms/initialState';
import { dateFormatMMDDYYYY } from 'helpers/constants';

export const UpdateAppForm: React.FC = () => {
  const { handleAction, form, deletedIds } = useAppForms();

  const { mutate: deleteFn } = useDeleteAppFormProperty();
  const { mutate: createPropertyFn } = useCreateFormProperty();
  const { mutate: createEdgeFn } = useCreateFormEdge();

  const onFinish = (values: AnyObject) => {
    let acc;
    let id = undefined;

    // WHEN form_item_type is true edges, opposite property

    if (values.form_item_type) {
      id = form[FormItemType.PROPERTY].find((item) => item.project_type_property_id === values?.form_item_id)?.id;
      acc = {
        project_edge_type_id: values?.form_item_id,
        id,
      };
    } else {
      id = form[FormItemType.PROPERTY].find((item) => item.project_type_property_id === values?.form_item_id)?.id;
      acc = {
        property_type_id: values?.form_item_id,
        id,
      };
    }

    const query: TFormType = {
      question: values?.question,
      hover: values?.hover,
      placeholder: values?.placeholder,
      required: values?.required ?? false,
      ...acc,
    };

    if (values?.min && values?.max) {
      query.validation = {
        min: values?.min,
        max: values?.max,
      };
    }

    if (values?.betweenDate) {
      query.validation = {
        from: dayjs(values?.betweenDate[0]).format(dateFormatMMDDYYYY),
        to: dayjs(values?.betweenDate[1]).format(dateFormatMMDDYYYY),
      };
    }

    !values?.form_item_type
      ? createPropertyFn(
          {
            id: form.id,
            query,
          },
          {
            onSuccess: (data) => {
              const { project_type_property_id, id } = data?.data;
              handleAction({
                type: APP_FORM_ACTIONS.SAVE_ITEM,
                payload: { project_type_property_id, id, type: FormItemType.PROPERTY },
              });
            },
          }
        )
      : createEdgeFn(
          { id: form.id, query },
          {
            onSuccess: (data) => {
              const { project_edge_type_id, id } = data?.data;
              handleAction({
                type: APP_FORM_ACTIONS.SAVE_ITEM,
                payload: { project_edge_type_id, id, type: FormItemType.EDGE },
              });
            },
          }
        );
  };

  const onHandleRemoveItem = useCallback(
    (deletedId: string, id: string, type: string) => {
      handleAction({
        type: APP_FORM_ACTIONS.DELETE_APP_FORM_ITEM,
        payload: {
          deletedId,
          id,
          type,
        },
      });
    },
    [handleAction]
  );

  const onReset = useCallback(() => {
    handleAction({
      type: APP_FORM_ACTIONS.DELETE_APP_FORM_ITEM,
      payload: deletedIdsInitialState,
    });
  }, [handleAction]);

  const onRemove = useCallback(() => {
    if (!!deletedIds.id) {
      deleteFn({ id: deletedIds.id, type: deletedIds.type });
    }
    handleAction({
      type: APP_FORM_ACTIONS.DELETE_FORM_ITEM,
      payload: { id: deletedIds.deletedId, type: deletedIds.type },
    });
    onReset();
  }, [deleteFn, deletedIds.deletedId, deletedIds.id, deletedIds.type, handleAction, onReset]);

  return (
    <>
      <Flex
        vertical
        style={{
          width: '100%',
        }}
      >
        <Header />
        <FormContent vertical>
          <Form.Provider>
            {form?.form_properties?.map((item) => (
              <Form
                variant="filled"
                key={item?.project_type_property_id}
                initialValues={{
                  ...item,
                  min: item?.validation?.min,
                  max: item?.validation?.max,
                  property_name: item?.property_type?.display_name,
                  form_item_id: item?.project_type_property_id,
                  betweenDate: [dayjs(item?.validation?.from), dayjs(item?.validation?.to)],
                }}
                layout="vertical"
                onFinish={onFinish}
              >
                <FormItem
                  id={item?.id}
                  isTouched={item?.touched}
                  name={item?.property_type?.display_name}
                  deleteId={item?.project_type_property_id}
                  type={item?.ref_property_type_id}
                  onDelete={onHandleRemoveItem}
                />
              </Form>
            ))}
            {form?.form_edges?.map((edge) => (
              <Form
                key={edge?.project_edge_type_id}
                layout="vertical"
                initialValues={{
                  ...edge,
                  form_item_id: edge?.project_edge_type_id,
                  property_name: edge?.edge_type?.name,
                }}
                onFinish={onFinish}
              >
                <FormItem
                  id={edge?.id}
                  isTouched={edge?.touched}
                  name={edge?.edge_type?.name}
                  deleteId={edge?.project_edge_type_id}
                  type={PropertyTypes.Connection}
                  isEdges={true}
                  onDelete={onHandleRemoveItem}
                />
              </Form>
            ))}
          </Form.Provider>
        </FormContent>
        <Footer />
      </Flex>
      <DeleteModal
        isOpen={!!deletedIds.deletedId}
        onClose={onReset}
        onFinish={onRemove}
        text="Are you sure you want to delete this form property?"
      />
      <AppFormView />
    </>
  );
};
