import { Divider, Flex, Form } from 'antd';
import { Input as InputComponent, TextArea } from 'components/input';
import { VerticalSpace } from 'components/space/vertical-space';
import { FormItem } from 'components/form/form-item';
import styled from 'styled-components';
import { Text } from 'components/typography';
import { AddColorIcon } from 'pages/project-overview/components/add-color-icon';
import { ProjectType } from 'pages/project-overview/components/project-type';
import { MargeFormProps } from 'types/marge';
import { useCallback } from 'react';

const Input = styled(InputComponent)`
  border: none;
  border-bottom: 1px solid #95a2e1;
  border-radius: 0;
  background: transparent;
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.07em;
`;

export const MargeForm: React.FC<MargeFormProps> = ({ form, name, setFormValues }) => {
  const onValuesChange = useCallback(
    (value: { title?: string } | undefined) => {
      if (setFormValues) {
        setFormValues(value?.title?.length);
      }
    },
    [setFormValues]
  );

  return (
    <Flex justify="center" align="center">
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
        style={{ padding: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px', width: '40vw' }}
      >
        <VerticalSpace size={10} className="overview-form-items">
          <VerticalSpace size={42}>
            <Flex gap={22}>
              <AddColorIcon />
              <VerticalSpace size={14}>
                <FormItem
                  name="title"
                  rules={[
                    { required: true, message: 'Title is required' },
                    { min: 3, message: 'The minimum length for this field is 3 characters' },
                  ]}
                  style={{ marginBottom: '0' }}
                >
                  <Input placeholder="Untitled" />
                </FormItem>
                <FormItem name="description" label="Description">
                  <TextArea rows={2} placeholder="Description" />
                </FormItem>
              </VerticalSpace>
            </Flex>
          </VerticalSpace>
          <Divider style={{ margin: '0' }} />
          <Text>Privacy</Text>
          <ProjectType name={name} />
        </VerticalSpace>
      </Form>
    </Flex>
  );
};
