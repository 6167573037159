import { Col, Flex, Image, Row } from 'antd';
import { Button, GridConnectionButton } from 'components/button';

import { Text, Title } from 'components/typography';
import { centerImageStyle, COLORS, STATUS_PROJECT } from 'helpers/constants';
import { useMemo } from 'react';
import { VerticalSpace } from 'components/space/vertical-space';
import { getRowData, groupedData } from './utils';
import { ReactComponent as InComeConnection } from 'components/icons/in-come.svg';
import { IViewNode } from 'types/node';
import { RejectApprove } from './reject-approve';

export const VIewNode: React.FC<IViewNode> = ({ data, dispatch, isShared = false, onClose }) => {
  const groupedDataList = useMemo(
    () => (data?.edges ? Object.entries(groupedData(data.edges.concat(data.edges_in))) : []),
    [data]
  );

  return (
    <VerticalSpace>
      {!isShared && (
        <Flex justify="end" gap={10} align="end" vertical>
          <Flex justify="end" gap={10} align="end" vertical>
            <Text color={COLORS.PRIMARY.BLUE}>
              By {data?.created?.first_name} {data?.created?.last_name}
            </Text>
          </Flex>
          <Flex justify="end" gap={10} align="end" vertical>
            {data?.status === STATUS_PROJECT.DRAFT && <RejectApprove id={data?.id} onClose={onClose} />}
          </Flex>
        </Flex>
      )}
      {data?.default_image && (
        <Image
          src={`${process.env.REACT_APP_AWS_URL}${data?.default_image}`}
          width={161}
          height={127}
          style={{ borderRadius: '4px', ...centerImageStyle }}
        />
      )}
      <VerticalSpace>
        <Text color={COLORS.PRIMARY.BLUE} style={{ fontSize: 24 }}>
          Name
        </Text>
        <Title level={3} style={{ fontSize: 20 }}>
          {data?.name}
        </Title>
      </VerticalSpace>
      {data?.properties ? (
        data.properties.map((data, index) => {
          return data.nodes_data?.length ? (
            <VerticalSpace key={data.id + index}>
              <Text color={COLORS.PRIMARY.BLUE} style={{ fontSize: 24 }}>
                {data.nodeTypeProperty.display_name}
              </Text>
              {getRowData(data)}
            </VerticalSpace>
          ) : null;
        })
      ) : (
        <></>
      )}
      {groupedDataList.map(([index, item]) => (
        <VerticalSpace key={index}>
          <Row>
            <Col>
              <Text color={COLORS.PRIMARY.BLUE} style={{ fontSize: 24 }}>
                {item[0]?.edgeTypes.name}
              </Text>
            </Col>
            <Col span={8} offset={1}>
              <GridConnectionButton
                type="text"
                size="small"
                backgroundColor={
                  data?.id !== item[0]?.source.id ? item[0]?.source.nodeType.color : item[0]?.target.nodeType.color
                }
                block
                icon={
                  <InComeConnection style={{ transform: `rotate(${data?.id !== item[0]?.source.id ? 180 : 0}deg)` }} />
                }
              >
                {data?.id !== item[0]?.source.id ? item[0]?.source.nodeType.name : item[0]?.target.nodeType.name}
              </GridConnectionButton>
            </Col>
            <Col
              span={24}
              style={{
                gap: '0.5rem',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {item?.map((row) => (
                <Button
                  type="text"
                  key={row.id}
                  onClick={() => dispatch?.(data?.id !== row.source.id ? row.source_id : row.target_id)}
                >
                  <Text underline color={COLORS.PRIMARY.GRAY_DARK} style={{ fontSize: 20 }}>
                    {data?.id !== row.source.id ? row.source.name : row.target.name}
                  </Text>
                </Button>
              ))}
            </Col>
          </Row>
        </VerticalSpace>
      ))}
    </VerticalSpace>
  );
};
