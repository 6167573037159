import { Table as DefaultTable } from 'antd';
import { COLORS } from 'helpers/constants';
import styled from 'styled-components';

export const Table = styled(DefaultTable)`
  & .ant-table-cell {
    border-color: ${COLORS.MAIN_GRAY};
  }
  & .ant-table-thead {
    box-shadow: 3px 3px 2px ${COLORS.MAIN_GRAY_SILVER};
  }
  & .ant-table-thead .ant-table-cell {
    color: ${COLORS.BLUE_10} !important;
    font-weight: 700;
    font-size: 18px;
  }
  & .ant-table-tbody .ant-table-cell {
    font-size: 16px;
  }
`;
