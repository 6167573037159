import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Form, Input, Upload, UploadFile } from 'antd';
import { Text } from 'components/typography';
import { AppFormItem } from '../style';
import { LinkOutlined } from '@ant-design/icons';
import { useAppForms } from 'context/app-forms';
import { useUpdateAppForm } from 'api/app-forms/use-update-app-form';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';
import { TextArea } from 'components/input/text-area';
import { removeTextSpaceDependOnLength } from 'helpers/utils';
import { useDeleteAppFormsImage } from 'api/app-forms/use-delete-form-image';
import { useParams } from 'react-router-dom';

enum InputTypes {
  NAME = 'name',
  DESCRIPTION = 'description',
}

export const Header: React.FC = () => {
  const { form, handleAction } = useAppForms();
  const [isUpdate, setIsUpdate] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [formData, setFormData] = useState({
    name: form.name,
    description: form.description,
  });

  const params = useParams();

  const [editMode, setEditMode] = useState({
    name: false,
    description: false,
  });

  const { mutate: updateFn } = useUpdateAppForm();
  const { mutate: deleteImage } = useDeleteAppFormsImage();

  const handleChange = (type: InputTypes.NAME | InputTypes.DESCRIPTION) => {
    setEditMode((prev) => ({ ...prev, [type]: true }));
  };

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: InputTypes.NAME | InputTypes.DESCRIPTION
  ) => {
    const value = await removeTextSpaceDependOnLength(e.target.value);
    if (value.length) {
      setFormData((prev) => ({ ...prev, [type]: e.target.value }));
    }
  };

  const handleInputBlur = useCallback(
    (type: InputTypes.NAME | InputTypes.DESCRIPTION) => {
      if (formData?.[type]?.length) {
        setEditMode((prev) => ({ ...prev, [type]: false }));
      }
      updateFn({ formId: form.id, params: formData });
    },
    [form.id, formData, updateFn]
  );

  const handleKeyDown = (e: React.KeyboardEvent, type: InputTypes.NAME | InputTypes.DESCRIPTION) => {
    if (e.key === 'Enter') {
      if (formData?.[type].length) {
        setEditMode((prev) => ({ ...prev, [type]: false }));
      }
      updateFn({ formId: form.id, params: formData });
    }
  };

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    if (fileList.length > 1) {
      setFileList([fileList[fileList.length - 1]]);
    } else {
      setFileList(fileList);
    }

    const formData = new FormData();

    fileList.forEach((file) => {
      if (file.originFileObj) {
        formData.append('file', file.originFileObj);
      }
    });

    updateFn(
      { formId: form.id, params: formData },
      {
        onSuccess: (data) => {
          handleAction({
            type: APP_FORM_ACTIONS.UPDATE_FORM_BACKGROUND,
            payload: data?.data?.background_image,
          });
          setIsUpdate(true);
          setTimeout(() => setIsUpdate(false), 1000);
        },
      }
    );
  };

  const handleFileRemove = () => {
    if (fileList.length > 0) {
      deleteImage(
        { id: params.id || '', formId: form.id },
        {
          onSuccess: () => {
            setFileList([]);
            handleAction({
              type: APP_FORM_ACTIONS.UPDATE_FORM_BACKGROUND,
              payload: null,
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (form.name || form.description) {
      setFormData({
        name: form?.name,
        description: form?.description,
      });
    }
    if (form?.background_image) {
      setFileList([
        {
          uid: '-1',
          name: 'background_image',
          url: process.env.REACT_APP_AWS_URL?.concat('', String(`${form.background_image}?t=${Date.now()}`)),
        },
      ]);
    }
  }, [form.background_image, form.description, form.name, isUpdate]);

  return (
    <Form
      style={{
        padding: 16,
        background: `url(${fileList[0]?.url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <AppFormItem justify="space-between" align="center">
        <Flex vertical gap={16}>
          {editMode.name ? (
            <Input
              value={formData.name}
              onChange={(e) => handleInputChange(e, InputTypes.NAME)}
              onBlur={() => handleInputBlur(InputTypes.NAME)}
              onKeyDown={(e) => handleKeyDown(e, InputTypes.NAME)}
              style={{ width: 320, fontSize: 16, height: 30 }}
            />
          ) : (
            <Text onDoubleClick={() => handleChange(InputTypes.NAME)} style={{ fontSize: 18, fontWeight: 700 }}>
              {formData.name}
            </Text>
          )}
          {editMode.description ? (
            <TextArea
              value={formData.description}
              onChange={(e) => handleInputChange(e, InputTypes.DESCRIPTION)}
              onBlur={() => handleInputBlur(InputTypes.DESCRIPTION)}
              onKeyDown={(e) => handleKeyDown(e, InputTypes.DESCRIPTION)}
              maxLength={500}
              required
              cols={100}
              autoSize={{ minRows: 2 }}
            />
          ) : (
            <Text onDoubleClick={() => handleChange(InputTypes.DESCRIPTION)} style={{ fontSize: 14, fontWeight: 700 }}>
              {formData.description}
            </Text>
          )}
        </Flex>

        <Form.Item name="file">
          <Upload
            fileList={fileList}
            maxCount={1}
            onChange={handleFileChange}
            beforeUpload={() => false}
            multiple={false}
            onRemove={handleFileRemove}
            accept=".png, .jpg, .jpeg, .svg"
          >
            <LinkOutlined />
            <Text style={{ fontSize: 16 }}>Attach file</Text>
          </Upload>
        </Form.Item>
      </AppFormItem>
    </Form>
  );
};
