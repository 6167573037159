import { useMutation } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { URL_POST_EXTERNAL_FORM } from './constant';
import { TExternalFormData } from './types';

/**
 * Custom hook for creating an external form.
 * Handles API calls and error messaging.
 */
export const useCreateExternalForm = () => {
  return useMutation({
    mutationFn: async (data: TExternalFormData) => {
      const url = URL_POST_EXTERNAL_FORM.replace(':id', data.id);
      return await client[RequestTypes.Post](url, data.formData);
    },
  });
};
