import React, { useCallback, useState } from 'react';
import { useSaveVisualization } from 'api/visualization-history/use-save-visualization';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';
import { getQueryFilterType, QueryFilterTypes } from 'components/select/queries-select';
import { SaveByTitleModal } from 'components/modal/save-by-title-modal';
import {
  EdgeStyleProperties,
  NodeStyleProperties,
  TNodeModelImg,
  TQueryStyling,
  TSaveDataStyles,
  TStyleQueryArrItem,
} from './types';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';
import { WarningModal } from 'components/modal/warning-modal';
import { isAxiosError } from 'axios';

export const NewVisualizationCreate: React.FC = () => {
  const { graph } = useGraph();
  const { mutate: saveGraphStylesFn, isLoading } = useSaveVisualization();
  const [isError, setIsError] = useState({
    has: false,
    message: '',
  });
  const { openSaveModal, visualizationId, queriesStyle, name, handleAction } = useVisualizationHistory();

  const onHandleToggleModal = useCallback(() => {
    handleAction({
      type: ACTIONS.OPEN_SAVE_MODAL,
      payload: {},
    });
  }, [handleAction]);

  const onHandleSaveStyles = useCallback(
    (data: { title: string }) => {
      const nodes: NodeStyleProperties[] = [];
      const edges: EdgeStyleProperties[] = [];
      const queryArr: TStyleQueryArrItem[] = [];

      // nodes set
      graph.getNodes().forEach((node) => {
        const { x, y, size, icon, label, color, nodeType } = node.getModel();
        const node_id = node.getID();

        let nodeData: NodeStyleProperties = {
          id: node_id,
          fx: x,
          fy: y,
          size: size ? +size : 40,
          color: typeof color === 'string' ? color : undefined,
        };

        if (typeof icon === 'object' && icon !== null && 'img' in icon) {
          nodeData.icon = (icon as TNodeModelImg).img || undefined;
        } else if (typeof icon === 'string') {
          nodeData.icon = icon;
        }

        const query = queriesStyle?.find((item) =>
          item.parent_id ? item.parent_id === nodeType : item.id === nodeType
        );

        if (query && query.label !== query.labelValue) {
          const isQueryNode =
            query.parent_id === nodeType && query.typeText.toLowerCase() === label?.toString().toLowerCase();

          if (isQueryNode) {
            nodeData = {
              ...nodeData,
              icon: query.icon || nodeData.icon,
              color: query.color || nodeData.color,
              size: query.size || nodeData?.size,
            };
          }
        } else {
          nodeData = {
            ...nodeData,
            icon: query?.icon || nodeData?.icon,
            color: query?.color || nodeData?.color,
            size: query?.size || nodeData?.size,
          };
        }

        nodes.push(nodeData);
      });

      // set edges

      graph.getEdges().map((edge) => {
        const { project_edge_type_id, style, type } = edge.getModel();
        const query = queriesStyle?.find((item) => item.id === project_edge_type_id);

        let edgesData: EdgeStyleProperties = {
          id: edge.getID(),
          size: style?.lineWidth as number,
          type: type === 'line' ? 'solid' : 'dashed',
          color: style?.stroke as string,
        };

        if (query) {
          edgesData = {
            ...edgesData,
            size: query.borderSize,
            type: query.borderDashed ? 'dashed' : 'solid',
            color: query.color,
          };
        }

        edges.push(edgesData);
      });

      queriesStyle?.map((query) => {
        const styling: TQueryStyling = query.isConnectionType
          ? {
              color: query.color,
              size: query.borderSize || 2,
              type: query.borderDashed ? 'dashed' : 'solid',
            }
          : {
              icon: query.icon || '',
              color: query.color,
              size: query.size || 40,
            };

        if (query.label === query.labelValue) {
          queryArr.push({
            type: query.isConnectionType ? 'edge' : 'node',
            label: query.label,
            project_type_id: query.id,
            styling,
            query: [],
          });
        } else {
          queryArr.push({
            type: query.isConnectionType ? 'edge' : 'node',
            label: query.label,
            project_type_id: query?.isConnectionType ? query.id : query.parent_id,
            styling: styling,
            query: !query?.isConnectionType
              ? [
                  {
                    property_type_id: query.id,
                    name: query.label,
                    type: query.ref_property_type_id,
                    action: getQueryFilterType(query.type as QueryFilterTypes),
                    value: query.typeText,
                  },
                ]
              : [],
          });
        }
      });

      const requestData: TSaveDataStyles = {
        id: visualizationId,
        name: data.title,
        queryArr,
        nodes,
        edges,
      };

      saveGraphStylesFn(requestData, {
        onSuccess: () => {
          handleAction({
            type: ACTIONS.CHANGE_NAME,
            payload: data.title,
          });
          onHandleToggleModal();
        },
        onError: (error) => {
          if (isAxiosError(error)) {
            const message = error.response?.data?.errors?.message;
            setIsError({
              has: true,
              message,
            });
          }
          onHandleToggleModal();
        },
      });
    },
    [graph, queriesStyle, visualizationId, saveGraphStylesFn, handleAction, onHandleToggleModal]
  );

  return (
    <>
      {!isError.has ? (
        <SaveByTitleModal
          isOpen={openSaveModal}
          onClose={onHandleToggleModal}
          onFinish={onHandleSaveStyles}
          isLoading={isLoading}
          title="Save Styles"
          name={name}
          label="Title"
        />
      ) : (
        <WarningModal
          isOpen={isError.has}
          onClose={() => setIsError({ has: false, message: '' })}
          title="Warning"
          text={isError.message}
        />
      )}
    </>
  );
};
