import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { Params, Options, IOptionsdata } from 'api/types';

export const useGetPersons = (
  params: Params,
  options: Options = { enabled: true }
): { isLoading: boolean; data: IOptionsdata } => {
  const url = '/projects/match';
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.post(url, { ...params }),
    ...options,
  });

  const { data, isSuccess, isLoading } = result;

  return { isLoading, data: isSuccess ? data?.data : {} };
};
