import { useState, useCallback } from 'react';
import { Flex, Select } from 'antd';
import { useGetAllProjectListMarge } from 'api/marge/get-project-list';
import { MargeDrawerPerson } from './mutch-person';
import { Text } from 'components/typography';
import {
  FetchDataResult,
  IFetchDataResult,
  FilterOptionsParams,
  HandleClearParams,
  HandleScrollParams,
  HandleSearchParams,
  MargeStepsDrawerProprtyProps,
  OptionType,
} from 'types/marge';

export const MargeStepsDrawerProprty = ({
  selectedSourceValue,
  setSelectedSourceValue,
  selectedTargetValue,
  setSelectedTargetValue,
  projectInfo,
  isNextDisabled,
}: MargeStepsDrawerProprtyProps) => {
  const { Option } = Select;

  const [sourceSearchTerm, setSourceSearchTerm] = useState('');
  const [targetSearchTerm, setTargetSearchTerm] = useState('');
  const [sourcePage, setSourcePage] = useState(1);
  const [targetPage, setTargetPage] = useState(1);
  const [allSourceData, setAllSourceData] = useState<FetchDataResult | undefined>(undefined);
  const [allTargetData, setAllTargetData] = useState<FetchDataResult | undefined>(undefined);
  const [hasMoreSourceData, setHasMoreSourceData] = useState(true);
  const [hasMoreTargetData, setHasMoreTargetData] = useState(true);

  const useFetchData = (
    searchTerm: string,
    page: number,
    type: 'source' | 'target'
  ): { data: FetchDataResult | undefined; isLoading: boolean } => {
    const { data, isLoading } = useGetAllProjectListMarge(
      searchTerm.length >= 3 ? { page, size: 20, search: searchTerm } : { page, size: 20, start_id: projectInfo?.id },
      {
        onSuccess: (data: IFetchDataResult) => {
          if (type === 'source') {
            setAllSourceData((prevData) => ({
              rows: page === 1 ? data?.data?.rows : [...(prevData?.rows || []), ...data?.data?.rows],
              count: data?.data?.count,
            }));

            setHasMoreSourceData(page < Math.ceil(data?.data?.count / 20));
          } else {
            setAllTargetData((prevData) => ({
              rows: page === 1 ? data?.data?.rows : [...(prevData?.rows || []), ...data?.data?.rows],
              count: data?.data?.count,
            }));

            setHasMoreTargetData(page < Math.ceil(data?.data?.count / 20));
          }
        },
      } as never
    );
    return { data: data as FetchDataResult | undefined, isLoading };
  };

  const { data: sourceData, isLoading: sourceLoading } = useFetchData(sourceSearchTerm, sourcePage, 'source');
  const { data: targetData, isLoading: targetLoading } = useFetchData(targetSearchTerm, targetPage, 'target');

  const handleSearch = useCallback(({ setSearchTerm, setPage, value }: HandleSearchParams) => {
    setSearchTerm(value);
    setPage(1);
    setAllSourceData(undefined);
    setAllTargetData(undefined);
  }, []);

  const handleScroll = useCallback(({ setPage, loading, dataCount, page, hasMoreData }: HandleScrollParams) => {
    return (e: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const bottomThreshold = 1;
      const isNearBottom = scrollHeight - (scrollTop + clientHeight) <= bottomThreshold;

      if (!hasMoreData) return;

      if (isNearBottom && !loading && dataCount && dataCount > 0 && hasMoreData) {
        const maxPages = Math.ceil(dataCount / 20);
        if (page < maxPages) {
          setPage((prev) => prev + 1);
        }
      }
    };
  }, []);

  const handleClear =
    ({ setSearchTerm, setSelectedValue }: HandleClearParams) =>
    () => {
      setSelectedValue(undefined);
      setSearchTerm('');
    };

  const filterOptions = ({ data, searchTerm, selectedValue }: FilterOptionsParams): OptionType[] =>
    data?.rows
      ?.filter((row) => row.title.toLowerCase().includes(searchTerm.toLowerCase()) && row.id !== selectedValue)
      .map((row) => ({
        label: row.title,
        value: row.id,
      })) || [];

  const sourceOptions = filterOptions({
    data: allSourceData,
    searchTerm: sourceSearchTerm,
    selectedValue: selectedTargetValue,
  });

  const targetOptions = filterOptions({
    data: allTargetData,
    searchTerm: targetSearchTerm,
    selectedValue: selectedSourceValue,
  });

  return (
    <Flex vertical gap={20}>
      <Text color="#484848" style={{ fontSize: 25, fontWeight: 700, textAlign: 'center' }}>
        Choose the projects you want to merge
      </Text>
      <Flex
        gap={50}
        vertical
        style={{ height: isNextDisabled ? `calc(45vh - 250px)` : `calc(71vh - 250px)` }}
        justify={!isNextDisabled ? 'start' : 'center'}
      >
        <Flex gap={10}>
          <Select
            style={{ width: '50%', height: 'auto' }}
            placeholder="Source Select"
            value={selectedSourceValue}
            onChange={setSelectedSourceValue}
            showSearch
            onSearch={(value) =>
              handleSearch({
                setSearchTerm: setSourceSearchTerm,
                setPage: setSourcePage,
                searchTerm: sourceSearchTerm,
                value,
              })
            }
            onPopupScroll={handleScroll({
              setPage: setSourcePage,
              loading: sourceLoading,
              dataCount: sourceData?.count,
              page: sourcePage,
              hasMoreData: hasMoreSourceData,
            })}
            loading={sourceLoading}
            filterOption={false}
            allowClear
            onClear={handleClear({ setSearchTerm: setSourceSearchTerm, setSelectedValue: setSelectedSourceValue })}
          >
            {sourceOptions?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>

          <Select
            style={{ width: '50%', height: 'auto' }}
            placeholder="Target Select"
            value={selectedTargetValue}
            onChange={setSelectedTargetValue}
            showSearch
            onSearch={(value) =>
              handleSearch({
                setSearchTerm: setTargetSearchTerm,
                setPage: setTargetPage,
                searchTerm: targetSearchTerm,
                value,
              })
            }
            onPopupScroll={handleScroll({
              setPage: setTargetPage,
              loading: targetLoading,
              dataCount: targetData?.count,
              page: targetPage,
              hasMoreData: hasMoreTargetData,
            })}
            loading={targetLoading}
            filterOption={false}
            allowClear
            onClear={handleClear({ setSearchTerm: setTargetSearchTerm, setSelectedValue: setSelectedTargetValue })}
          >
            {targetOptions?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Flex>

        {selectedTargetValue && selectedSourceValue && (
          <MargeDrawerPerson selectedTargetValue={selectedTargetValue} selectedSourceValue={selectedSourceValue} />
        )}
      </Flex>
    </Flex>
  );
};
