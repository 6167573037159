import { useState } from 'react';
import { Col, Drawer, Flex, Form, Modal, Row, Steps } from 'antd';
import { Button } from 'components/button';
import { SecondaryText } from 'components/typography';
import styled, { CSSProperties } from 'styled-components';
import { MargeStepsDrawerProprty } from './proprty';
import { MargeForm } from './margeForm';
import { useMargeProject } from 'api/marge/use-marges-project';
import { CreateStepsProps, MargeStepsDrawerProps, Step } from 'types/marge';
import { ProgressBar } from 'components/progress-bar';

export const StyledSteps = styled(Steps)`
  .ant-steps-item {
    .ant-steps-icon {
      color: #c3c3c3;
      disabled?: boolean;
    }
    .ant-steps-item-icon {
      background-color: transparent;
      border-color: #c3c3c3;
    }
    .ant-steps-item-tail {
      &::after {
        height: 1.5px;
      }
    }
    .ant-steps-item-title .ant-typography {
      color: #c3c3c3;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    &.ant-steps-item-active {
      .ant-steps-icon {
        color: #ffffff;
      }
      .ant-steps-item-icon {
        background-color: #89d9b3;
        border-color: #89d9b3;
      }
      .ant-steps-item-tail {
        &::after {
          background: linear-gradient(270deg, #808080 0.27%, #89d9b3 99.42%);
        }
      }
      .ant-steps-item-title .ant-typography {
        color: #000;
        font-weight: 600;
      }
    }
    &.ant-steps-item-finish {
      .ant-steps-icon {
        color: #ffffff;
      }
      .ant-steps-item-icon {
        background-color: #89d9b3;
        border-color: #89d9b3;
      }
      .ant-steps-item-tail {
        &::after {
          background: linear-gradient(270deg, #cde4d9 0.27%, #89d9b3 99.42%);
        }
      }
      .ant-steps-item-title .ant-typography {
        color: #89d9b3;
        font-weight: 600;
      }
    }
  }
`;

export const createSteps = ({
  selectedSourceValue,
  setSelectedSourceValue,
  selectedTargetValue,
  setSelectedTargetValue,
  form,
  projectInfo,
  isNextDisabled,
  setFormValues,
}: CreateStepsProps): Step[] => [
  {
    title: <SecondaryText>Step 1</SecondaryText>,
    content: (
      <MargeStepsDrawerProprty
        selectedSourceValue={selectedSourceValue}
        setSelectedSourceValue={setSelectedSourceValue}
        selectedTargetValue={selectedTargetValue}
        setSelectedTargetValue={setSelectedTargetValue}
        projectInfo={projectInfo}
        isNextDisabled={isNextDisabled}
      />
    ),
  },
  {
    title: <SecondaryText>Step 2</SecondaryText>,
    content: <MargeForm form={form} name={'marge'} setFormValues={setFormValues} />,
  },
];

export const defaultValuesMarge = {
  color: '#DBDBDB',
  icon: 'araks-small',
};
const headerStyle: CSSProperties = {
  border: '1px solid #F2F2F2',
  borderRadius: '4px',
  background: 'rgba(255, 255, 255, 0.60)',
  boxShadow: '0px 10px 10px 0px rgba(141, 143, 166, 0.20)',
  backdropFilter: 'blur(7px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  flexShrink: '0',
};

export const MargeStepsDrawer = ({ open, setOpen, projectInfo }: MargeStepsDrawerProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedSourceValue, setSelectedSourceValue] = useState<string | undefined>(projectInfo?.id);
  const [selectedTargetValue, setSelectedTargetValue] = useState<string | undefined>(undefined);
  const [formValues, setFormValues] = useState<number | undefined>(0);

  const [form] = Form.useForm();

  const { mutate, isLoading } = useMargeProject({});
  const isNextDisabled = !selectedSourceValue || !selectedTargetValue || isLoading;

  const steps = createSteps({
    selectedSourceValue,
    setSelectedSourceValue,
    selectedTargetValue,
    setSelectedTargetValue,
    form,
    projectInfo,
    isNextDisabled,
    setFormValues,
  });

  const items = steps?.map((step, index) => ({
    key: index,
    title: step.title,
    disabled: isNextDisabled,
  }));

  const handleNext = async () => {
    const formData = form.getFieldsValue();
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      try {
        await mutate({
          color: formData.color ?? defaultValuesMarge.color,
          icon: formData.icon ?? defaultValuesMarge.icon,
          privacy: formData.privacy ?? 'public',
          source: selectedSourceValue ?? '',
          target: selectedTargetValue ?? '',
          title: formData.title,
          description: formData.description,
        });
        form.submit();
      } catch (submitError) {}
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    } else {
      Modal.confirm({
        title: 'Are you sure you want to cancel current marge process? All data will be cleared.',
        onOk: () => {
          setOpen(false), setSelectedTargetValue(undefined);
        },
      });
    }
  };

  const handleStepClick = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <Drawer
      title={<StyledSteps current={currentStep} items={items} labelPlacement="vertical" onChange={handleStepClick} />}
      closable={false}
      open={open}
      destroyOnClose
      placement="right"
      width={'100%'}
      style={{
        margin: '100px auto',
        width: '55vw',
        height: isNextDisabled ? '50vh' : currentStep === 1 ? '63vh' : '74vh',
      }}
      headerStyle={headerStyle}
      footerStyle={{
        boxShadow:
          '-6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05)',
      }}
      footer={
        <Row justify="end" gutter={32}>
          <Col span={5}>
            <Button type="default" block onClick={handleBack}>
              {currentStep === 0 ? 'Cancel' : 'Prev'}
            </Button>
          </Col>
          <Col span={5}>
            <Button
              block
              type="primary"
              onClick={handleNext}
              disabled={isNextDisabled || (currentStep === 1 && (formValues ?? 0) < 3 && formValues !== undefined)}
            >
              {currentStep === steps.length - 1 ? 'Merge' : 'Next'}
            </Button>
          </Col>
        </Row>
      }
    >
      <Flex vertical style={{ marginTop: '20px' }}>
        {steps[currentStep].content}
      </Flex>
      <ProgressBar start={isLoading} stop={!isLoading} />
    </Drawer>
  );
};
