import { Flex } from 'antd';
import { Button } from 'components/button';

import { useCallback, useState } from 'react';
import { VerticalSpace } from 'components/space/vertical-space';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { RejectModal } from 'components/modal/reject-node-modal';
import { useGetRejectApprowed } from 'api/schema/type/use-get-draft';
import { useGetProjecTPermission } from 'api/project-node-types/use-get-permisson';
import { useParams } from 'react-router-dom';
import { ROLES_PROJECT } from 'helpers/constants';
import { TPermissionProject } from 'api/types'; 

 
type IRejectModal = {
  id: string | string[];
  onClose?: () => void;
  setCheckedItems?: (items: string[]) => void;
};

export const RejectApprove: React.FC<IRejectModal> = ({ id, onClose, setCheckedItems }) => {
  const params = useParams();

  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [permissionUser, setPermissionUser] = useState<TPermissionProject | null>(null);
  const [modalAction, setModalAction] = useState<'reject' | 'approve'>('reject');

  useGetProjecTPermission(params.id || '', {
    enabled: true,
    onSuccess(data) {
      setPermissionUser(data as never);
    },
  });
  const { mutate: updateReject } = useGetRejectApprowed();

  const updateRejectStatus = (status: 'reject' | 'approve') => {
    setModalAction(status);
    setIsOpenRejectModal(true);
  };

  const onFinish = useCallback(() => {
    const status = modalAction === 'reject' ? 'rejected' : 'approved';

    updateReject(
      {
        ids: Array.isArray(id) ? id : [id],
        status: status,
      },
      {
        onSuccess: () => {
          if (onClose) {
            onClose();
          }
          setCheckedItems && setCheckedItems([]);
        },
      }
    );
  }, [id, modalAction, onClose, setCheckedItems, updateReject]);

  return (
    <VerticalSpace>
      {permissionUser?.role === ROLES_PROJECT.OWNER && (
        <Flex gap={16} justify="end">
          <Button type="primary" onClick={() => updateRejectStatus('approve')}>
            <CheckOutlined />
            Approve
          </Button>
          <Button onClick={() => updateRejectStatus('reject')}>
            <CloseOutlined />
            Reject
          </Button>
        </Flex>
      )}
      <RejectModal
        title=""
        text={
          modalAction === 'reject'
            ? 'Are you sure you wish to reject this data?'
            : 'Are you sure you wish to approve this data?'
        }
        isOpen={isOpenRejectModal}
        onClose={() => setIsOpenRejectModal(false)}
        onFinish={onFinish}
      />
    </VerticalSpace>
  );
};
