import styled from 'styled-components';
import { Row, Typography } from 'antd';

const Text = Typography;

export const StyledText = styled(Text)`
  color: #414141;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.4px;
  margin-bottom: 8px;
`;

/**
 * @description that query styles
 */

export const Header = styled(Row)`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const Title = styled(Row)<{ backgroundColor?: string }>`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  padding: 4px;
`;

export const ItemBox = styled(Row)<{ backgroundColor?: string }>`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  &:hover {
    background-color: ${(props) => (props.backgroundColor !== 'transparent' ? props.backgroundColor : `#c5c2c2`)};
  }
`;
