import { useMutation, useQueryClient } from '@tanstack/react-query';
import { errorMessage } from 'helpers/utils';
import client from '../client';
import { GET_QUERY_BY_PROJECTID } from './use-get-histories';
import { AnyObject } from 'antd/es/_util/type';

export const POST_CREATE_HISTORY = '/neo4j/query-history/:id';

export interface IQueryArr {
  type: string;
  label: string;
  color: string;
  query_history_node_type_id?: string;
  query_history_edge_type_id?: string;
  query: AnyObject;
}

export type TQueryProps = {
  name: string;
  description: string;
  operator: 'AND' | 'OR';
  queryArr: IQueryArr[];
};

interface QueryCreateResponse {
  id: string;
}

export const useCreateQuery = (id: string) => {
  const queryClient = useQueryClient();
  const urlNodes = POST_CREATE_HISTORY.replace(':id', id);

  const mutation = useMutation<QueryCreateResponse, unknown, TQueryProps>(
    (values: TQueryProps) => client.post(urlNodes, values).then((data) => data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_QUERY_BY_PROJECTID.replace(':id', id)]);
      },
      onError: errorMessage,
    }
  );

  return mutation;
};
