import { useQuery } from '@tanstack/react-query';
import client from 'api/client';
import { Params, ProjectListResponse, UseGetAllProjectListMargeResult } from 'api/types';

export const useGetAllProjectListMarge = (params: Params, options?: Params): UseGetAllProjectListMargeResult => {
  const url = '/projects/autocomplete';
  const result = useQuery<ProjectListResponse>({
    queryKey: [url, params],
    queryFn: () => client.get(url, { params }),
    ...options,
  });

  const { data, isSuccess, isLoading } = result;

  return {
    data: isSuccess ? data?.data : {},
    isSuccess,
    isLoading,
  };
};
