import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from 'api/client';
import { RequestTypes } from 'api/types';
import { URL_APP_FORMS_DELETE_IMG, URL_APP_FORMS_LISTS } from './constant';

/**
 * The mutation function to delete a form.
 *
 * @param data - An object containing the `id` (project ID) and `formId` (form ID).
 */

export const useDeleteAppFormsImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { id: string; formId: string }) =>
      client[RequestTypes.Delete](URL_APP_FORMS_DELETE_IMG.replace(':project_id', data.id).replace(':id', data.formId)),
    onSuccess: () => {
      queryClient.invalidateQueries([URL_APP_FORMS_LISTS]);
    },
  });
};
