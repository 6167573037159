import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex, TablePaginationConfig, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useAppForms } from 'context/app-forms';
import { useGetAppFormsLists } from 'api/app-forms/use-get-app-forms-lists';
import { useDeleteAppForms } from 'api/app-forms/use-delete-app-forms';
import { Table } from 'components/table';
import { Text } from 'components/typography';
import { CopyLink } from 'components/actions/copy-link';
import { DeleteModal } from 'components/modal/delete-modal';
import { ReactComponent as RemoveIcon } from 'components/icons/remove-icon.svg';
import { columns, styles } from './constant';
import { COLORS, dateFormat, PATHS } from 'helpers/constants';
import { APP_FORM_ACTIONS } from 'context/app-forms/reducer';

export const FormLists: React.FC = () => {
  const [open, setIsOpen] = useState(false);
  const [formParams, setIsFormParams] = useState({ id: '', formId: '' });

  const { handleAction, params } = useAppForms();

  const { data, isLoading: isDataLoading } = useGetAppFormsLists(params);
  const { mutate: deleteFn, isLoading: isDeleteLoading } = useDeleteAppForms();

  const onToggleModal = () => setIsOpen((prev) => !prev);

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      handleAction({
        type: APP_FORM_ACTIONS.CHANGE_PARAMS,
        payload: { ...params, page: pagination?.current || params.page },
      });
    },
    [handleAction, params]
  );

  const onFinish = () => {
    deleteFn(formParams);
    onToggleModal();
  };

  const handleDeleteForm = (id: string, formId: string) => {
    setIsFormParams({ id, formId });
    onToggleModal();
  };

  const dataSource =
    data?.rows?.map((item) => ({
      project_name: (
        <Link to={PATHS.PROJECT_OVERVIEW.replace(':id', item?.project?.id)} style={styles.link}>
          <Tooltip title={item?.project?.title}>
            {item?.project?.title.length >= 30 ? `${item?.project?.title.slice(0, 30)}...` : item?.project?.title}
          </Tooltip>
        </Link>
      ),
      title: (
        <Link to={PATHS.APP_FORMS.replace(':id', item?.project?.id).concat('form_id=', item?.id)} style={styles.link}>
          <Tooltip title={item?.name}>{item?.name.length >= 30 ? `${item?.name.slice(0, 30)}...` : item?.name}</Tooltip>
        </Link>
      ),
      status:
        item?.status === 'disable_specific'
          ? `disable on ${dayjs(item?.expired_date).format(dateFormat)}`
          : item.status,
      created_at: dayjs(item?.created_at).format(dateFormat),
      applicants: <Text style={{ color: COLORS.BLUE_10, fontSize: 16 }}>{item?.applicants}</Text>,
      link: <CopyLink path={PATHS.APP_FORM_EXTERNAL.replace(':id', item?.id)} />,
      action: (
        <Flex justify="center" style={{ cursor: 'pointer' }}>
          <RemoveIcon onClick={() => handleDeleteForm(item?.project?.id, item?.id)} style={{ width: 16, height: 16 }} />
        </Flex>
      ),
    })) || [];

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={[...columns]}
        bordered
        loading={isDataLoading || isDeleteLoading}
        size="large"
        pagination={
          Number(data?.count) > Number(params?.size)
            ? {
                total: data?.count,
                current: params.page,
                pageSize: params.size,
              }
            : false
        }
        onChange={(pagination) => handleTableChange(pagination)}
      />
      <DeleteModal
        isOpen={open}
        onClose={onToggleModal}
        text="Are you sure you want to delete this form?"
        onFinish={onFinish}
      />
    </>
  );
};
