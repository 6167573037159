import { Tree, Divider, Flex, Badge, Empty, TreeDataNode, Spin } from 'antd';
import { useGetPersons } from 'api/marge/use-get-person';
import { IOptionsdata } from 'api/types';
import { Text } from 'components/typography';
import styled from 'styled-components';
import { MargeDrawerPersonProps, NotMatchData, Person, Property } from 'types/marge';

const StyledBadge = styled(Badge)`
  && {
    .ant-badge-status-dot {
      height: 16px;
      width: 16px;
    }
  }
`;

export const MargeDrawerPerson = ({ selectedSourceValue, selectedTargetValue }: MargeDrawerPersonProps) => {
  const { data, isLoading }: { isLoading?: boolean; data?: IOptionsdata & { not_match?: NotMatchData } } =
    useGetPersons({
      source: selectedSourceValue,
      target: selectedTargetValue,
    });

  const sourceMatchData: Person[] = Array.isArray(data?.match) ? data.match.map((p: [Person, Person]) => p[0]) : [];
  const targetMatchData: Person[] = Array.isArray(data?.match) ? data.match.map((p: [Person, Person]) => p[1]) : [];

  const sourceNotMatchData = data?.not_match?.[selectedSourceValue] || [];
  const targetNotMatchData = data?.not_match?.[selectedTargetValue] || [];

  const transformToTreeData = (matchData: Person[]): TreeDataNode[] => {
    return matchData?.map((item) => ({
      title: item.name,
      key: item.id,
      icon: <StyledBadge color={item.color} />,
      children: item?.properties?.map((prop: Property) => ({
        title: prop.display_name,
        key: prop.id,
      })),
    }));
  };

  const treeDataSourceMatch = transformToTreeData(sourceMatchData);
  const treeDataTargetMatch = transformToTreeData(targetMatchData);
  const treeDataSourceNotMatch = transformToTreeData(sourceNotMatchData as never);
  const treeDataTargetNotMatch = transformToTreeData(targetNotMatchData as never);

  return (
    <Flex gap={20} vertical>
      {isLoading ? (
        <Flex justify="center" style={{ width: '100%' }}>
          <Spin size="large" />
        </Flex>
      ) : (
        <>
          {data?.match?.length > 0 ? (
            <>
              <Text style={{ textAlign: 'center', fontSize: 32 }}>Matched Data</Text>
              <Flex gap={50} style={{ width: '100%' }} justify="space-around">
                <Flex style={{ width: '100%' }} vertical gap={10}>
                  <Text style={{ textAlign: 'center', fontSize: 24 }}>Source Data</Text>
                  <Tree treeData={treeDataSourceMatch} showIcon selectable={false} />
                </Flex>
                <Flex style={{ width: '100%' }} vertical gap={10}>
                  <Text style={{ textAlign: 'center', fontSize: 24 }}>Target Data</Text>
                  <Tree treeData={treeDataTargetMatch} showIcon selectable={false} />
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex justify="center" vertical>
              <Text style={{ textAlign: 'center', fontSize: 32 }}>Matched Data</Text>
              <Empty />
            </Flex>
          )}

          {data?.not_match ? (
            <>
              <Divider style={{ width: '100%', margin: 0 }} />
              <Text style={{ textAlign: 'center', fontSize: 32 }}>Unmatched Data</Text>
              <Flex gap={50} style={{ width: '100%' }} justify="space-around">
                <Flex style={{ width: '100%' }} vertical gap={10}>
                  <Text style={{ textAlign: 'center', fontSize: 24 }}>Source Data</Text>
                  <Tree treeData={treeDataSourceNotMatch} showIcon selectable={false} />
                </Flex>
                <Flex style={{ width: '100%' }} vertical gap={10}>
                  <Text style={{ textAlign: 'center', fontSize: 24 }}>Target Data</Text>
                  <Tree treeData={treeDataTargetNotMatch} showIcon selectable={false} />
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex justify="center" vertical>
              <Text style={{ textAlign: 'center', fontSize: 32 }}>Unmatched Data</Text>
              <Empty />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};
