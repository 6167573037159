import { CopyOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Flex, message, Typography } from 'antd';
import { COLORS } from 'helpers/constants';
import React, { useState } from 'react';

const { Link } = Typography;

const BASE_URL = window.location.origin;

export const CopyLink: React.FC<{ path: string }> = ({ path }) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const handleClickCopy = async () => {
    try {
      const link = BASE_URL.concat('', path);
      navigator.clipboard.writeText(link);
      setIsCopy(true);
      message.success('Link copied to clipboard!');
      setTimeout(() => setIsCopy(false), 2000);
    } catch (error) {
      message.error('Failed to copy link. Please try again.');
    }
  };

  return (
    <Flex gap={10} align="center" style={{ cursor: 'pointer' }}>
      {!isCopy ? (
        <CopyOutlined onClick={handleClickCopy} />
      ) : (
        <FileDoneOutlined style={{ color: COLORS.SECONDARY.GREEN }} />
      )}
      <Link style={{ color: COLORS.BLUE_10, fontSize: 16, fontWeight: 700 }} onClick={handleClickCopy}>
        {isCopy ? 'Copied' : 'Copy Link'}
      </Link>
    </Flex>
  );
};
