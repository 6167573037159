import { Dispatch, SetStateAction } from 'react';
import { Flex, Form } from 'antd';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { Input } from 'components/input';
import { getQueryFilterType, QueryFilterTypes } from 'components/select/queries-select';
import { getQueryValue } from 'helpers/utils';
import { IQueryArr, useCreateQuery } from 'api/query-history/use-create-history';
import { useParams } from 'react-router-dom';
import { TreeConnectionType, TreeNodeType } from 'pages/data-sheet/types';
import { QueriesTypes } from 'helpers/constants';

type FormPropsValues = {
  name: string;
  description: string;
  operator: 'AND' | 'OR';
  queryArr: Array<{
    type: string;
    label: string;
    query_history_node_type_id?: string;
    query: {
      delete_type?: {
        query_history_node_property_id?: string;
        type: string;
        action: string;
        multiple: boolean;
        value: '';
      };
    };
  }>;
};

type FormQueryValues = {
  operator: 'AND' | 'OR';
  queries: (
    | TreeNodeType
    | (TreeConnectionType & {
        type: QueryFilterTypes;
        typeText: string;
      })
  )[];
};

type Props = {
  isModalOpen: boolean;
  closePreview?: () => void;
  setIsModalOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  defaultformData: FormQueryValues;
  setActiveQuerisID?: (activeQuerisID: { name: string; id: string } | null) => void;
  setQueryCollapsed?: Dispatch<SetStateAction<boolean>>;
};

export const SaveQueryModal = ({
  isModalOpen,
  setIsModalOpen,
  closePreview,
  defaultformData,
  setQueryCollapsed,
  setActiveQuerisID,
}: Props) => {
  const params = useParams();

  const [form] = Form.useForm();

  const { mutate } = useCreateQuery(params.id ? params.id : '');

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = ({ name }: { name: string }) => {
    const formData: FormPropsValues = {
      name,
      description: '',
      operator: 'OR',
      queryArr: [],
    };

    if (defaultformData) {
      const queryArr: IQueryArr[] = [];

      defaultformData.queries?.forEach((item) => {
        const isConnection = item.isConnectionType;
        const isDepthValid = (isConnection && item.depth !== 3) || (!isConnection && item.depth === 1);
        const hasProjectEdge = isConnection && item.depth !== 1;
        let query; // default value is undefined

        const obj: IQueryArr = {
          type: isConnection ? 'relation' : 'node',
          label: item.labelValue,
          color: item.color,
          query_history_node_type_id: isConnection ? undefined : item.parent_id ?? item.id,
          query_history_edge_type_id: isConnection ? item.parent_id ?? item.id : undefined,
          ...(isDepthValid ? { action: getQueryFilterType(item.type) } : {}),
          ...(hasProjectEdge ? { project_edge_type_id: item.id } : {}),
          query: {},
        };

        if (defaultformData.operator === 'OR') {
          query = {
            query_history_node_property_id: isConnection ? undefined : item.id,
            id: isConnection ? item.id : undefined,
            type: item.ref_property_type_id,
            action: getQueryFilterType(item.type) || QueriesTypes.IS_NOT_NULL,
            multiple: item.multiple_type,
            value: getQueryValue(item),
          };
        } else {
          query = {
            name: item.name,
            type: item.ref_property_type_id,
            display_name: item.label,
            value: getQueryValue(item),
            action: getQueryFilterType(item.type),
            query_history_node_property_id: isConnection ? undefined : item.id,
            query_history_edge_property_id: isConnection ? item.id : undefined,
            multiple_type: item.multiple_type,
            isConnectionType: isConnection,
          };
        }

        if (isConnection || !item?.parent_id) {
          obj.query = {};
        } else {
          obj.query[item.name] = query;
        }
        queryArr.push(obj);
      });

      const updatedFormData = {
        ...formData,
        operator: defaultformData.operator,
        queryArr,
      };

      if (queryArr.length) {
        mutate(updatedFormData, {
          onSuccess: (response) => {
            setActiveQuerisID?.({ name, id: response.id });
            setQueryCollapsed?.(true);
          },
        });
      }
    }

    setIsModalOpen(false);
    form.resetFields();
    if (closePreview) closePreview();
  };

  return (
    <>
      <Modal open={isModalOpen} footer={false} closable={false} title="Save Query">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Title" name="name" rules={[{ required: true, max: 30, min: 3 }]}>
            <Input placeholder="Title" />
          </Form.Item>
          <Flex justify="end" align="center" gap={16}>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};
