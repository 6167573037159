import { List, Skeleton, Empty } from 'antd';
import { useGetNotificationsAllData } from 'api/notifications/use-get-notifications-all-data';
import { NotificationsData } from 'api/types';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NotificationItem } from './notification-item';
import { NotificationsPage, NotificationsStatusFilter } from './types';

type Props = {
  page: NotificationsPage;
  result: NotificationsData[];
  setResult: Dispatch<SetStateAction<NotificationsData[]>>;
  setPage: Dispatch<SetStateAction<NotificationsPage>>;
};

export const NotificationsList = ({ page: { status, ...page }, result, setResult, setPage }: Props) => {
  const { count, isInitialLoading } = useGetNotificationsAllData(
    {
      ...page,
      ...(status === NotificationsStatusFilter.Unread ? { status: NotificationsStatusFilter.Unread } : {}),
    },
    {
      onSuccess: ({ rows, count }) => {
        setResult((prev) => {
          const uniqueResults = Array.from(new Map([...prev, ...rows].map((item) => [item.id, item]))).map(
            ([, value]) => value
          );
          return uniqueResults;
        });
      },
    }
  );

  const loadMoreData = useCallback(() => {
    if (isInitialLoading || result.length >= count) return;
    if (status === NotificationsStatusFilter.Unread) {
      setPage((prev) => ({ ...prev, page: 1 }));
    } else {
      setPage((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [isInitialLoading, result.length, count, setPage, status]);

  const hasMore = useMemo(() => result.length < count, [result.length, count]);

  return (
    <div
      id="scrollableDiv"
      className="scroll-container"
      style={{ height: 'auto', overflow: 'scroll', maxHeight: '60vh' }}
    >
      <InfiniteScroll
        dataLength={result.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
      >
        {result.length === 0 && !isInitialLoading ? (
          <Empty description="No notifications available" />
        ) : (
          <List
            dataSource={result}
            itemLayout="horizontal"
            renderItem={(item) => (
              <NotificationItem item={item} key={item.id} setResult={setResult} setPage={setPage} />
            )}
          />
        )}
      </InfiniteScroll>
    </div>
  );
};
