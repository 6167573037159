import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Text } from 'components/typography';
import { COLORS } from 'helpers/constants';
import { useGetQueryByProjectId } from 'api/query-history/use-get-histories';
import { ReactComponent as DelateSvg } from 'components/icons/delete_outline-simple.svg';
import { DeleteQueryeModal } from './DelateQueryModal';
import { Header, ItemBox, Title } from '../styles';

type Props = {
  activeQuerisID: { name: string; id: string } | null;
  setActiveQuerisID: (activeQuerisID: { name: string; id: string } | null) => void;
  isShared?: boolean;
};

type itemProps = {
  id: string;
  name: string;
};

export const QuerySection = ({ activeQuerisID, setActiveQuerisID, isShared = false }: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<string | null>(null);

  const params = useParams();

  const data = useGetQueryByProjectId(params.id ?? '', {
    enabled: !!params.id && !isShared,
  });

  return (
    <Row style={{ width: '100%', overflow: 'hidden', minWidth: 200 }}>
      <Header>
        <Text>Saved Queries({data !== undefined && data.length})</Text>
      </Header>
      <Title
        onClick={() => setActiveQuerisID(null)}
        backgroundColor={activeQuerisID === null ? COLORS.PRIMARY.GRAY_DARK : 'transparent'}
      >
        <Text
          color={activeQuerisID === null ? COLORS.PRIMARY.WHITE : COLORS.PRIMARY.GRAY_DARK}
          style={{ fontSize: 20, fontWeight: 600 }}
        >
          New Query
        </Text>
      </Title>
      {data !== undefined &&
        data.map((e: itemProps) => (
          <ItemBox
            backgroundColor={
              activeQuerisID !== null && activeQuerisID.id === e.id ? COLORS.PRIMARY.GRAY_DARK : 'transparent'
            }
            key={e.id}
            onClick={() => setActiveQuerisID({ name: e.name, id: e.id })}
          >
            <Text
              color={
                activeQuerisID !== null && activeQuerisID.id === e.id ? COLORS.PRIMARY.WHITE : COLORS.PRIMARY.GRAY_DARK
              }
            >
              {e.name}
            </Text>
            <Col onClick={() => setIsDeleteModalOpen(e.id)}>
              <DelateSvg />
            </Col>
          </ItemBox>
        ))}
      {isDeleteModalOpen !== null && (
        <DeleteQueryeModal
          onSetActiveQuerisID={setActiveQuerisID}
          isModalOpen={isDeleteModalOpen !== null ? true : false}
          setIsModalOpen={setIsDeleteModalOpen}
          projectId={params.id ?? ''}
          queryId={isDeleteModalOpen}
        />
      )}
    </Row>
  );
};
