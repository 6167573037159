import React, { memo, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button, Flex, Form } from 'antd';
import { useCreateExternalForm } from 'api/app-forms/use-create-external-form';
import { PropertyTypes } from 'components/form/property/types';
import {
  BooleanType,
  ConnectionType,
  DateTimeType,
  DateType,
  NumericType,
  RichTextType,
  TextType,
  DocumentType,
  EnumType,
  ImageType,
} from 'components/form/type/app-form-types';
import { AnyObject } from 'antd/es/_util/type';
import { COLORS, dateFormatMMDDYYYY } from 'helpers/constants';
import { AppForm } from './styled';
import { IFormViewProps } from '../type';
import { Header } from './header';
import { ReactComponent as SuccessIcon } from 'components/icons/success-message.svg';
import { Text } from 'components/typography';
import _ from 'lodash';
import { isAxiosError } from 'axios';

export const FormView: React.FC<IFormViewProps> = memo(({ data, url, appFormId, isSubmit }) => {
  const [form] = Form.useForm();

  const { mutate: submitFn, isLoading, isSuccess } = useCreateExternalForm();

  const onFinish = async (values: AnyObject) => {
    try {
      const formData = new FormData();
      // Process properties
      Object.keys(values)
        .filter((key) => values[key] && !key.includes('_edge'))
        .forEach((id, index) => {
          const value = values[id];

          if (value?.fileList?.length > 0) {
            const file = value.fileList[0]?.originFileObj;
            const extension = file.name.split('.').pop();
            const fileName = `${id}_${Date.now()}.${extension}`;
            formData.append(`nodes[${index}][id]`, id);
            formData.append(`nodes[${index}][value][0]`, id);
            formData.append('files', file, fileName);
          } else if (typeof value === 'object' && dayjs(value).isValid()) {
            formData.append(`nodes[${index}][id]`, id);
            formData.append(`nodes[${index}][value][0]`, dayjs(value).format(dateFormatMMDDYYYY));
          } else if (typeof value !== 'object') {
            formData.append(`nodes[${index}][id]`, id);
            formData.append(`nodes[${index}][value][0]`, value);
          }
        });

      // Process edges
      Object.keys(values)
        ?.filter((i) => values[i] && i.includes('_edge'))
        ?.map((id, index) => {
          const value = values[id];
          formData.append(`target_edges[${index}][id]`, id.replace('_edge', ''));
          formData.append(`target_edges[${index}][target_id]`, value);
        });

      // Submit the form
      await submitFn(
        { id: appFormId, formData },
        {
          onError: (error: unknown) => {
            if (isAxiosError(error)) {
              if (error.response?.data?.errors?.validations) {
                const mappedFields = error.response.data.errors.validations.map(
                  (err: { name: string; errors: string[] }) => ({
                    name: err.name,
                    errors: err.errors,
                  })
                );
                form.setFields(mappedFields);
              }
            } else {
              throw new Error('An unknown error occurred while submitting the form');
            }
          },
        }
      );
    } catch (error) {
      throw new Error('An unknown error occurred while creating formData');
    }
  };

  const debounceReload = _.debounce(() => {
    window.location.reload();
  }, 3000);

  useEffect(() => {
    if (isSuccess) {
      debounceReload();
    }
    return () => debounceReload.cancel();
  }, [debounceReload, isSuccess]);

  if (isSuccess) {
    return (
      <Flex justify="center" vertical gap={16} align="center" style={{ height: '100vh' }}>
        <SuccessIcon />
        <Text style={{ fontSize: 32, color: COLORS.BLUE_10 }}>Succesfully sent </Text>
      </Flex>
    );
  }

  return (
    <AppForm>
      <Header url={String(url)} title={data?.name} description={data?.description} />
      <Form layout="vertical" form={form} onFinish={onFinish} className="application-form">
        {data?.form_properties.map((item) => {
          switch (item?.property_type?.ref_property_type_id) {
            case PropertyTypes.Text:
              return <TextType key={item?.id} {...item} />;
            case PropertyTypes.Boolean:
              return <BooleanType key={item?.id} {...item} />;
            case PropertyTypes.Integer:
              return <NumericType key={item.id} {...item} />;
            case PropertyTypes.Decimal:
              return <NumericType key={item?.id} {...item} />;
            case PropertyTypes.Connection:
              return <ConnectionType />;
            case PropertyTypes.RichText:
              return <RichTextType key={item?.id} {...item} />;
            case PropertyTypes.Date:
              return <DateType key={item?.id} {...item} />;
            case PropertyTypes.DateTime:
              return <DateTimeType key={item?.id} {...item} />;
            case PropertyTypes.Document:
              return <DocumentType key={item?.id} {...item} />;
            case PropertyTypes.ENUM:
              return <EnumType key={item?.id} {...item} />;
            case PropertyTypes.IMAGE_URL:
              return <ImageType key={item?.id} {...item} />;
            default:
              return <TextType key={item?.id} {...item} />;
          }
        })}
        {data?.form_edges?.map((item) => {
          return <ConnectionType key={item?.id} {...item} />;
        })}
        {isSubmit && (
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        )}
      </Form>
    </AppForm>
  );
});
