import { useMutation, useQueryClient } from '@tanstack/react-query';
import { URL_GET_PROJECT_ALL_DATA } from 'api/all-data/constants';
import client from 'api/client';
import { URL_NODES_LIST } from 'api/node/constants';
import { useDataSheetWrapper } from 'components/layouts/components/data-sheet/wrapper';
import { useParams } from 'react-router-dom';

const URL_GET_DRAFT_REJECT = '/nodes/status/:project_id';

interface IData {
  ids: string[];
  status: string;
}

export const useGetRejectApprowed = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { nodeTypeId } = useDataSheetWrapper();

  const mutation = useMutation({
    mutationFn: (data: IData) => {
      return client.put(URL_GET_DRAFT_REJECT.replace(':project_id', id ?? ''), data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([URL_GET_PROJECT_ALL_DATA.replace(':project_id', id as string)]);
      queryClient.invalidateQueries([
        URL_NODES_LIST.replace(':project_id', id || '').replace(':project_type_id', nodeTypeId || ''),
      ]);
    },
  });
  return mutation;
};
