import React from 'react';
import { Flex, Form, Select, Tooltip } from 'antd';
import { Text } from 'components/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { VALIDATE_MESSAGES } from 'helpers/constants';

export const EnumType: React.FC<AnyObject> = ({ id, question, hover, required, property_type, placeholder }) => {
  const label = (
    <Flex gap={16} align="center">
      <Text>{question}</Text>
      {hover && (
        <Tooltip title={hover}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Flex>
  );

  /**
   * @description When property type has enums_data create options
   *
   * @type {Array<{id: string, name: string}>} enums_data
   */

  const options = property_type?.enums_data?.map(({ id, name }: { id: string; name: string }) => ({
    label: name,
    value: id,
  }));

  return (
    <Form.Item label={label} name={id} rules={[{ required, message: VALIDATE_MESSAGES.required }]}>
      <Select options={options} placeholder={placeholder}/>
    </Form.Item>
  );
};
